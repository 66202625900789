import {APPURLPREFIXES} from "../constants/PatientPortalEnums";

const createMessageText = (message, intl, app) => {

    let thoughtQuestion = intl.formatMessage({id: "createmessagetext.question.thought"});
    let concernQuestion = intl.formatMessage({id: "createmessagetext.question.concern"});

    if (app === APPURLPREFIXES.RELATIVECONTACT) {
        thoughtQuestion = intl.formatMessage({id: "childorrelative.fquestions.question.what"});
        concernQuestion = intl.formatMessage({id: "childorrelative.fquestions.question.concern"});
    }

    const wishQuestion = intl.formatMessage({id: "createmessagetext.question.wish"});
    const newLine = "\r\n";

    const patientText = message.patientText ? message.patientText : ""
    const thoughtText = message.thoughtText ? `${thoughtQuestion}
    ${message.thoughtText}` : ""
    const concernText = message.concernText ? `${concernQuestion}
    ${message.concernText}` : ""
    const wishText = message.wishText ? `${wishQuestion}
    ${message.wishText}` : ""

    let allText = "";
    if (patientText) {
        allText = patientText + newLine;
    }
    if (thoughtText) {
        allText += newLine + thoughtText + newLine;
    }
    if (concernText) {
        allText += newLine + concernText + newLine;
    }
    if (wishText) {
        allText += newLine + wishText + newLine;
    }

    if (allText.trim() !== "") {
        return (allText);
    }

};
export default createMessageText;
