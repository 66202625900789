import {
  EmailInputWithLabelAndError,
  H1,
  PhoneInputWithLabelAndError,
} from "@collabodoc/component-library";
import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import validator from "validator";
import { BackFrontButton } from "../../components/BackFrontButtons";
import { RegistrationContext } from "../../RegistrationContext";
import { VIEWS } from "../../URLS";

const PatientContactInfoView = () => {
  const intl = useIntl();

  const {
    setPatientContactInfo,
    patientContactInfo,
    formIsSent,
    message,
    contactReasonId,
    subjectState,
    childInfo,
  } = useContext(RegistrationContext);
  const navigate = useNavigate();
  const [tempPhoneNumber, setTempPhoneNumber] = useState(
    patientContactInfo.phoneNumber || ""
  );
  const [tempEmail, setTempEmail] = useState(patientContactInfo.email || "");
  const [phoneValidationError, setPhoneValidationError] = useState(null);
  const [emailValidationError, setEmailValidationError] = useState(null);

  const setPhoneNumber = (input) => {
    setTempPhoneNumber(input);
    if (input !== null) {
      const [isValid] = validatePhoneNumber(input);
      setPhoneValidationError(
        isValid
          ? null
          : intl.formatMessage({ id: "validationerror.phonenumber" })
      );
    }
  };

  const setEmail = (input) => {
    setTempEmail(input);
    if (input) {
      setEmailValidationError(
        validator.isEmail(input)
          ? null
          : intl.formatMessage({ id: "validationerror.email" })
      );
    } else {
      setEmailValidationError(null);
    }
  };

  const validatePhoneNumber = (input) => {
    let tempPhone = input.toString();
    tempPhone = tempPhone.replace("-", "");
    if (tempPhone.substring(0, 2) === "07") {
      tempPhone = "+46" + tempPhone.substring(1, tempPhone.length);
    } else if (tempPhone.substring(0, 2) === "00") {
      tempPhone.replace(tempPhone.substring(0, 2), "+");
    }
    const re = new RegExp(
      "^(([0]{1}|[0]{2}[4][6]|[+]{1}[4][6])[7]{1}\\d{1}-?\\d{7})$"
    );
    if (re.test(tempPhone)) {
      return [true, tempPhone];
    }
    return [false, null];
  };

  const handleBack = () => {
    navigate(VIEWS.FQUESTIONS);
  };

  const handleClick = (e) => {
    e.preventDefault();
    const [, formattedPhoneNumber] = validatePhoneNumber(tempPhoneNumber);
    setPatientContactInfo({
      phoneNumber: formattedPhoneNumber,
      email: tempEmail ? tempEmail : null,
    });
    navigate(VIEWS.CONFIRMMESSAGE);
  };

  const disabled =
    !tempPhoneNumber || !!(phoneValidationError || emailValidationError);

  const allPreviousSelfInfo = message.patientText && contactReasonId;
  const allPreviousChildInfo =
    childInfo.firstName && childInfo.lastName && childInfo.personalNumber;

  if (
    (subjectState.isSelf && (formIsSent || !allPreviousSelfInfo)) ||
    (!subjectState.isSelf &&
      (formIsSent || !allPreviousChildInfo || !allPreviousSelfInfo))
  ) {
    return <Navigate to={VIEWS.CHOOSEISSUEOWNER} />;
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage id="patientcontactinfo.header" />
        </H1>
        <PhoneInputWithLabelAndError
          label={intl.formatMessage({ id: "mobilenumber" })}
          id={"patientPhoneNumber"}
          autoComplete={"tel"}
          value={tempPhoneNumber}
          required={true}
          handleChange={setPhoneNumber}
          validationError={phoneValidationError}
        />
        <br />
        <br />
        <EmailInputWithLabelAndError
          label={intl.formatMessage({ id: "email" })}
          id={"patientEmail"}
          autoComplete={"email"}
          value={tempEmail}
          handleChange={setEmail}
          required={false}
          validationError={emailValidationError}
        />
        <br />
        <br />
        <BackFrontButton
          disabled={disabled}
          handleBack={handleBack}
          handleForward={handleClick}
          backBtnText={intl.formatMessage({ id: "back" })}
          forwardBtnText={intl.formatMessage({ id: "next" })}
        />
      </Col>
    </Row>
  );
};
export default PatientContactInfoView;
