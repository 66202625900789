import { withOidcSecure } from "@axa-fr/react-oidc";
import { UnexpectedErrorView } from "@collabodoc/component-library";
import React from "react";
import { Navigate, useRouteError } from "react-router-dom";
import Callback from "./CallBack";
import CareCenterRedirect from "./components/CareCenterRedirect";
import { RegistrationContextProvider } from "./RegistrationContext";
import Root from "./Root";
import { RCVIEWS, VIEWS } from "./URLS";
import AnswerView from "./views/digitalcontact/AnswerView";
import CareCenterRequirement from "./views/digitalcontact/CareCenterRequirement";
import ChooseCareCenterView from "./views/digitalcontact/ChooseCareCenterView";
import ChooseIssueOwnerView from "./views/digitalcontact/ChooseIssueOwnerView";
import ConfirmMessageView from "./views/digitalcontact/ConfirmMessageView";
import FQuestionsView from "./views/digitalcontact/FQuestionsView";
import InformationView from "./views/digitalcontact/InformationView";
import IssueCreatedView from "./views/digitalcontact/IssueCreatedView";
import PatientContactInfoView from "./views/digitalcontact/PatientContactInfoView";
import PatientQuestionView from "./views/digitalcontact/PatientQuestionView";
import PatientView from "./views/digitalcontact/PatientView";
import RegisterChildView from "./views/digitalcontact/RegisterChildView";
import RCAnswerView from "./views/relativecontact/AnswerView";
import RCCareCenterRequirement from "./views/relativecontact/CareCenterRequirement";
import RCChooseCareCenterView from "./views/relativecontact/ChooseCareCenterView";
import RCChooseIssueOwnerView from "./views/relativecontact/ChooseIssueOwnerView";
import RCConfirmMessageView from "./views/relativecontact/ConfirmMessageView";
import RCFQuestionsView from "./views/relativecontact/FQuestionsView";
import RCInformationView from "./views/relativecontact/InformationView";
import RCIssueCreatedView from "./views/relativecontact/IssueCreatedView";
import RCPatientContactInfoView from "./views/relativecontact/PatientContactInfoView";
import RCPatientQuestionView from "./views/relativecontact/PatientQuestionView";
import RCPatientView from "./views/relativecontact/PatientView";
import RCRegisterChildView from "./views/relativecontact/RegisterChildView";
import RCTermsAndConditionsView from "./views/relativecontact/TermsAndConditionsView";

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  return <UnexpectedErrorView />;
};

export const routes = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={"/digitalcontact"} />,
      },
      {
        path: VIEWS.CALLBACK,
        element: <Callback />,
      },
      {
        path: "/information/:carecenterId",
        element: <CareCenterRedirect url={VIEWS.INFORMATION} />,
      },
      {
        path: "/patient/:carecenterId",
        element: <CareCenterRedirect url={VIEWS.PATIENT} />,
      },
      {
        path: "/digitalcontact",
        element: <CareCenterRequirement />,
        children: [
          {
            index: true,
            Component: InformationView,
          },
          {
            path: VIEWS.CHOOSE_CARECENTER,
            Component: ChooseCareCenterView,
          },
          {
            path: VIEWS.INFORMATION,
            Component: () => <Navigate to={"/digitalcontact"} />,
          },
          {
            path: VIEWS.PATIENT,
            Component: withOidcSecure(PatientView),
          },
          {
            path: VIEWS.ANSWER,
            Component: withOidcSecure(AnswerView),
          },
          {
            path: "",
            Component: RegistrationContextProvider,
            children: [
              {
                path: VIEWS.CHOOSEISSUEOWNER,
                Component: withOidcSecure(ChooseIssueOwnerView),
              },
              {
                path: VIEWS.REGISTERCHILD,
                Component: withOidcSecure(RegisterChildView),
              },
              {
                path: VIEWS.PATIENTQUESTION,
                Component: withOidcSecure(PatientQuestionView),
              },
              {
                path: VIEWS.FQUESTIONS,
                Component: withOidcSecure(FQuestionsView),
              },
              {
                path: VIEWS.PATIENTCONTACTINFO,
                Component: withOidcSecure(PatientContactInfoView),
              },
              {
                path: VIEWS.CONFIRMMESSAGE,
                Component: withOidcSecure(ConfirmMessageView),
              },
              {
                path: VIEWS.CONFIRMATION,
                Component: withOidcSecure(IssueCreatedView),
              },
            ],
          },
        ],
      },
      {
        path: "/relativecontact",
        Component: RCCareCenterRequirement,
        children: [
          {
            index: true,
            Component: RCInformationView,
          },
          {
            path: RCVIEWS.CHOOSE_CARECENTER,
            Component: RCChooseCareCenterView,
          },
          {
            path: RCVIEWS.INFORMATION,
            Component: () => <Navigate to={"/relativecontact"} />,
          },
          {
            path: RCVIEWS.TERMSANDCONDITIONS,
            Component: RCTermsAndConditionsView,
          },
          {
            path: RCVIEWS.PATIENT,
            Component: withOidcSecure(RCPatientView),
          },
          {
            path: RCVIEWS.ANSWER,
            Component: withOidcSecure(RCAnswerView),
          },
          {
            path: "",
            Component: RegistrationContextProvider,
            children: [
              {
                path: RCVIEWS.CHOOSEISSUEOWNER,
                Component: withOidcSecure(RCChooseIssueOwnerView),
              },
              {
                path: RCVIEWS.REGISTERCHILD,
                Component: withOidcSecure(RCRegisterChildView),
              },
              {
                path: RCVIEWS.PATIENTQUESTION,
                Component: withOidcSecure(RCPatientQuestionView),
              },
              {
                path: RCVIEWS.FQUESTIONS,
                Component: withOidcSecure(RCFQuestionsView),
              },
              {
                path: RCVIEWS.PATIENTCONTACTINFO,
                Component: withOidcSecure(RCPatientContactInfoView),
              },
              {
                path: RCVIEWS.CONFIRMMESSAGE,
                Component: withOidcSecure(RCConfirmMessageView),
              },
              {
                path: RCVIEWS.CONFIRMATION,
                Component: withOidcSecure(RCIssueCreatedView),
              },
            ],
          },
        ],
      },
    ],
  },
];
