import React, { useContext, useEffect } from "react";
import { CareCenterContext } from "../../components/CareCenterContext";
import ChatMessage from "./ChatMessage";
import { useIntl } from "react-intl";

const ChatInner = ({ issue, currentSender }) => {
  const intl = useIntl();
  const { currentCareCenter } = useContext(CareCenterContext);

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.scrollHeight;
  }, []);

  return (
    <>
      {issue.messages?.map((message) => (
        <ChatMessage
          message={message}
          key={message.messageId}
          currentSender={currentSender}
          currentSenderName={intl.formatMessage({ id: "you" })}
          currentReceiverName={currentCareCenter && currentCareCenter.name}
        />
      ))}
    </>
  );
};
export default ChatInner;
