import "bootstrap/dist/css/bootstrap.min.css";
import "./Content/Style/font.css";
import "./Content/Style/footer.css";
import "./Content/Style/general.css";
import "./Content/Style/header.css";
import "./Content/Style/input.css";
import "./Content/Style/image.css";
import "./Content/Style/leftToRight.css";
import "./Content/Style/section.css";
import "./Content/Style/table.css";
import "./Content/Style/button.css";
import "./Content/Style/font-src.css";
import "./Content/font-awesome.min.css";
import "./Content/Style/SCSS/custom.scss";
import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import { getTranslations, LOCALE } from "./utils/localeUtils";
import packageJson from "../package.json";
import { CacheBuster } from "@collabodoc/component-library";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    {" "}
    <CacheBuster currentVersion={packageJson.version}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return <App />;
      }}
    </CacheBuster>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
