const PATIENT_CONTACT_BACKEND_API = `${window.REACT_APP_PATIENT_CONTACT_API_URL}/api`;

const API_URLS = {
  GET_CARECENTERS: `${PATIENT_CONTACT_BACKEND_API}/patient/carecenters/`,
  CONTACTREASONS: `${PATIENT_CONTACT_BACKEND_API}/contactreasons/`,
  ISSUES: (careCenterId) =>
    `${PATIENT_CONTACT_BACKEND_API}/patient/carecenters/${careCenterId}/issues/`,
  ISSUE: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/patient/carecenters/${careCenterId}/issues/${issueId}`,
  DOWNLOAD: (careCenterId, fileId) =>
    `${PATIENT_CONTACT_BACKEND_API}/patient/carecenters/${careCenterId}/issues/download?fileId=${fileId}`,
  ISSUE_MESSAGE_READ: (careCenterId, issueId, messageId) =>
    `${PATIENT_CONTACT_BACKEND_API}/patient/carecenters/${careCenterId}/issues/${issueId}/messages/${messageId}/read`,
  ISSUE_MESSAGES: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/patient/carecenters/${careCenterId}/issues/${issueId}/messages`,
};

const VIEWS = {
  CHOOSE_CARECENTER: "/digitalcontact/choosecarecenter",
  VISITTYPE: "/digitalcontact/visittype",
  INFORMATION: "/digitalcontact/information",
  INFORMATION_CARECENTER: "/digitalcontact/information/:careCenterId",
  PATIENT_CARECENTER: "/digitalcontact/patient/:careCenterId",
  PATIENT: "/digitalcontact/patient",
  ANSWER: "/digitalcontact/issue",
  CALLBACK: "/callback",
  CHOOSEISSUEOWNER: "/digitalcontact/chooseissueowner",
  PATIENTQUESTION: "/digitalcontact/patientquestions",
  REGISTERCHILD: "/digitalcontact/registerchild",
  FQUESTIONS: "/digitalcontact/fquestions",
  PATIENTCONTACTINFO: "/digitalcontact/contactinfo",
  CONFIRMMESSAGE: "/digitalcontact/confirmmessage",
  CONFIRMATION: "/digitalcontact/confirmation",
};

const RCVIEWS = {
  CHOOSE_CARECENTER: "/relativecontact/choosecarecenter",
  VISITTYPE: "/relativecontact/visittype",
  INFORMATION: "/relativecontact/information",
  INFORMATION_CARECENTER: "/relativecontact/information/:careCenterId",
  TERMSANDCONDITIONS: "/relativecontact/termsandcondition",
  PATIENT_CARECENTER: "/relativecontact/patient/:careCenterId",
  PATIENT: "/relativecontact/patient",
  ANSWER: "/relativecontact/issue",
  CHOOSEISSUEOWNER: "/relativecontact/chooseissueowner",
  PATIENTQUESTION: "/relativecontact/patientquestions",
  REGISTERCHILD: "/relativecontact/registerchild",
  FQUESTIONS: "/relativecontact/fquestions",
  PATIENTCONTACTINFO: "/relativecontact/contactinfo",
  CONFIRMMESSAGE: "/relativecontact/confirmmessage",
  CONFIRMATION: "/relativecontact/confirmation",
};

export { API_URLS, VIEWS, RCVIEWS };
