import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import properNameCase from "proper-name-case";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  B,
  H1,
  H3,
  LoadingView,
  PrimaryButton,
  useGet,
} from "@collabodoc/component-library";
import { API_URLS, RCVIEWS } from "../../URLS";
import RIssueListItem from "./IssueListItem";
import { FormattedMessage } from "react-intl";
import { CareCenterContext } from "../../components/CareCenterContext";

const PatientView = () => {
  const { accessToken } = useOidcAccessToken();
  const { oidcUser } = useOidcUser();

  const navigate = useNavigate();
  const { currentCareCenterId } = useContext(CareCenterContext);
  const { response: issues = [], isLoading } = useGet(
    API_URLS.ISSUES(currentCareCenterId),
    accessToken
  );
  if (!oidcUser) {
    return <LoadingView />;
  }
  const handleClick = () => {
    navigate(RCVIEWS.CHOOSEISSUEOWNER);
  };

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage
            id="patient.header"
            description="Welcome Name"
            values={{ name: properNameCase.convert(oidcUser.given_name) }}
          />
        </H1>
        <PrimaryButton onClick={handleClick}>
          <FormattedMessage id="newissue" /> <span className="fa fa-plus" />
        </PrimaryButton>
        <br />
        <br />
        {isLoading ? (
          <LoadingView />
        ) : issues.length ? (
          <div>
            <H3>
              <FormattedMessage id="patient.ongoingissues" />
            </H3>
            {issues?.map((issue) => (
              <RIssueListItem issue={issue} key={issue.issueId} />
            ))}
          </div>
        ) : (
          <div className="well">
            <B>
              <FormattedMessage
                id="patient.noongoingissues"
                description="You have no ongoing issues"
              />
            </B>
            <FormattedMessage
              id="patient.readanswerhere"
              description="You can read the answer from the carecenter here..."
            />
            <br />
            <br />
          </div>
        )}
      </Col>
    </Row>
  );
};
export default PatientView;
