import {
  HorizontalLine,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { BackFrontButton } from "../../components/BackFrontButtons";
import { FQUESTIONS } from "../../constants/PatientPortalEnums";
import { RegistrationContext } from "../../RegistrationContext";
import { RCVIEWS } from "../../URLS";

const FQuestionsView = () => {
  const intl = useIntl();
  const { subjectState, message, setMessage } = useContext(RegistrationContext);
  const navigate = useNavigate();

  const handleThoughtInput = (value) => {
    setMessage((prevState) => ({
      ...prevState,
      thoughtText: value,
    }));
  };

  const handleConcernInput = (value) => {
    setMessage((prevState) => ({
      ...prevState,
      concernText: value,
    }));
  };

  const handleWishInput = (value) => {
    setMessage((prevState) => ({
      ...prevState,
      wishText: value,
    }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate(RCVIEWS.PATIENTCONTACTINFO);
  };

  const handleBack = () => {
    navigate(RCVIEWS.PATIENTQUESTION);
  };

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <strong>
          <FormattedMessage
            id="fquestions.header"
            description="Please answer questions so we can help you"
          />
        </strong>
        <HorizontalLine />
        <Row>
          <Col>
            <TextAreaWithLabelAndError
              label={intl.formatMessage({
                id: subjectState.isSelf
                  ? "fquestions.question.what"
                  : "childorrelative.fquestions.question.what",
              })}
              id={FQUESTIONS.THOUGHT}
              value={message.thoughtText}
              placeholder={intl.formatMessage({ id: "describe" })}
              autoFocus={true}
              minRows={2}
              required={false}
              handleChange={handleThoughtInput}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextAreaWithLabelAndError
              label={intl.formatMessage({
                id: subjectState.isSelf
                  ? "fquestions.question.concern"
                  : "childorrelative.fquestions.question.concern",
              })}
              id={FQUESTIONS.CONCERN}
              value={message.concernText}
              placeholder={intl.formatMessage({ id: "describe" })}
              minRows={2}
              required={false}
              handleChange={handleConcernInput}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextAreaWithLabelAndError
              label={intl.formatMessage({
                id: "fquestions.question.expectations",
              })}
              id={FQUESTIONS.WISH}
              value={message.wishText}
              placeholder={intl.formatMessage({ id: "describe" })}
              minRows={2}
              required={false}
              handleChange={handleWishInput}
            />
          </Col>
        </Row>
        <br />
        <BackFrontButton
          handleBack={handleBack}
          handleForward={handleClick}
          backBtnText={intl.formatMessage({ id: "back" })}
          forwardBtnText={intl.formatMessage({ id: "next" })}
        />
      </Col>
    </Row>
  );
};
export default FQuestionsView;
