import React from 'react';
import { PrimaryButton } from '@collabodoc/component-library';


export const BackButton = (props) => {
    return (
        <PrimaryButton onClick={props.handleBack} className="rightMargin-md"><span className="fa fa-chevron-left rightMargin-md"/>{props.backBtnText}</PrimaryButton>
    );
}

export const BackFrontButton = (props) => {
    return (
        <div className="textCenter">
            <PrimaryButton onClick={props.handleBack} className="rightMargin-md"><span className="fa fa-chevron-left rightMargin-md"/>{props.backBtnText}</PrimaryButton>
            <PrimaryButton disabled={props.disabled} onClick={props.handleForward} className="leftMargin-md">{props.forwardBtnText}<span className="fa fa-chevron-right leftMargin-md"/></PrimaryButton>
        </div>
    );
}
