import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import {
  B,
  emptyResultResolver,
  H1,
  P,
  PrimaryButton,
  SuccessButton,
  usePost,
} from "@collabodoc/component-library";
import properNameCase from "proper-name-case";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BackButton } from "../../components/BackFrontButtons";
import { CareCenterContext } from "../../components/CareCenterContext";
import createMessageText from "../../components/createMessageText";
import { APPURLPREFIXES } from "../../constants/PatientPortalEnums";
import { SESSION_STORAGE } from "../../constants/storage";
import { RegistrationContext } from "../../RegistrationContext";
import { API_URLS, VIEWS } from "../../URLS";

const Header = styled(B)`
  margin-bottom: 0px;
`;

const ConfirmMessageView = () => {
  const intl = useIntl();
  const { getContactReasonName } = useContext(RegistrationContext);
  const {
    subjectState,
    childInfo,
    contactReasonId,
    message,
    patientContactInfo,
    formIsSent,
    setFormIsSent,
    resetForm,
  } = useContext(RegistrationContext);
  const { accessToken } = useOidcAccessToken();
  const { oidcUser } = useOidcUser();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const chevron = collapsed ? "fa fa-chevron-down" : "fa fa-chevron-up";
  const disabled = !message.patientText || !patientContactInfo.phoneNumber;
  const childFirstName = childInfo.firstName ? childInfo.firstName : "";
  const childLastName = childInfo.lastName ? childInfo.lastName : "";
  const [disableSendButton, setDisableSendButton] = useState(false);

  const { currentCareCenterId } = useContext(CareCenterContext);
  const { doPost: sendIssue, error } = usePost(
    API_URLS.ISSUES(currentCareCenterId),
    accessToken,
    emptyResultResolver
  );
  const [messageText, setMessageText] = useState(null);

  useEffect(() => {
    message &&
      setMessageText(
        createMessageText(message, intl, APPURLPREFIXES.DIGITALCONTACT)
      );
  }, [message, intl]);

  const handleSave = async () => {
    setDisableSendButton(true);
    sendIssue({
      PhoneNumber: patientContactInfo.phoneNumber,
      MessageText: messageText,
      Email: patientContactInfo.email,
      FirstName: properNameCase.convert(oidcUser.given_name),
      LastName: properNameCase.convert(oidcUser.family_name),
      TimeOfRegistrationStart: sessionStorage.getItem(
        SESSION_STORAGE.TIME_OF_REGISTRATION_START
      ),
      ChildPersonalNumber: childInfo.personalNumber,
      ChildFirstName: childInfo.firstName,
      ChildLastName: childInfo.lastName,
      ContactReasonId: contactReasonId,
    }).then(() => {
      setFormIsSent(true);
      resetForm();
      navigate(VIEWS.CONFIRMATION);
    });
  };
  const handleBack = () => {
    navigate(VIEWS.PATIENTCONTACTINFO);
  };

  const handleReturn = () => {
    sessionStorage.removeItem(SESSION_STORAGE.TIME_OF_REGISTRATION_START);
    navigate(VIEWS.PATIENT);
  };

  const toggleQuestion = () => {
    setCollapsed(!collapsed);
  };

  if (error) {
    setDisableSendButton(false);
  }

  const allPreviousSelfInfo =
    message.patientText && contactReasonId && patientContactInfo.phoneNumber;
  const allPreviousChildInfo =
    childInfo.firstName && childInfo.lastName && childInfo.personalNumber;

  if (
    (subjectState.isSelf && (formIsSent || !allPreviousSelfInfo)) ||
    (!subjectState.isSelf &&
      (formIsSent || !allPreviousChildInfo || !allPreviousSelfInfo))
  ) {
    return <Navigate to={VIEWS.CHOOSEISSUEOWNER} />;
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage
            id="confirmmessage.header"
            description="Confirm content"
          />
        </H1>
        <div className="well">
          <Header>
            <FormattedMessage id="confirmmessage.yourphonenumber" />
          </Header>
          <P>{patientContactInfo.phoneNumber}</P>
          {patientContactInfo.email && (
            <Fragment>
              <Header>
                <FormattedMessage id="confirmmessage.youremail" />
              </Header>
              <P>{patientContactInfo.email}</P>
            </Fragment>
          )}
          {!subjectState.isSelf ? (
            <div>
              <Header>
                <FormattedMessage id="confirmmessage.childname" />
              </Header>
              <P>{`${childFirstName} ${childLastName}`}</P>
              <Header>
                <FormattedMessage id="confirmmessage.childpnr" />
              </Header>
              <P>{childInfo.personalNumber}</P>
            </div>
          ) : (
            ""
          )}
          <Header>
            <FormattedMessage id="confirmmessage.contactreason" />
          </Header>
          <P>{getContactReasonName(contactReasonId)}</P>
          <Header>
            <FormattedMessage id="confirmmessage.yourmessage" />
          </Header>
          {messageText && messageText.length > 100 ? (
            collapsed ? (
              <div className="preWrapText">
                {messageText.length > 100
                  ? messageText.substring(0, 100) + "..."
                  : messageText}
                <br />
                <PrimaryButton className="linkBtn" onClick={toggleQuestion}>
                  <span className={`${chevron}`} />
                  <FormattedMessage
                    id="confirmmessage.showmessage"
                    description="Show whole message"
                  />
                </PrimaryButton>
              </div>
            ) : (
              <div className="preWrapText">
                {messageText}
                <br />
                <PrimaryButton className="linkBtn" onClick={toggleQuestion}>
                  <span className={`${chevron}`} />
                  <FormattedMessage
                    id="confirmmessage.hidemessage"
                    description="Show less"
                  />
                </PrimaryButton>
              </div>
            )
          ) : (
            messageText
          )}
        </div>
        <div className="textCenter">
          <BackButton
            handleBack={handleBack}
            backBtnText={intl.formatMessage({ id: "back" })}
          />
          <SuccessButton
            disabled={disabled || disableSendButton}
            className="leftMargin-md"
            onClick={handleSave}
          >
            <FormattedMessage id="confirm" />
          </SuccessButton>
        </div>
        <br />
        <div className="textCenter">
          {error && (
            <B className="dangerText">
              <FormattedMessage
                id="confirmmessage.errortext"
                description="Error occured when sending message..."
                values={{
                  br: <br />,
                }}
              />
              <PrimaryButton className="linkBtnDanger" onClick={handleReturn}>
                <FormattedMessage id="here" />
              </PrimaryButton>
            </B>
          )}
        </div>
      </Col>
    </Row>
  );
};
export default ConfirmMessageView;
