import { H1, TextInputWithLabelAndError } from "@collabodoc/component-library";
import Personnummer from "personnummer";
import properNameCase from "proper-name-case";
import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import { BackFrontButton } from "../../components/BackFrontButtons";
import { RegistrationContext } from "../../RegistrationContext";
import { VIEWS } from "../../URLS";

const CHILD_MAX_AGE = 17;
const RegisterChildView = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { childInfo, setChildInfo, formIsSent } =
    useContext(RegistrationContext);
  const [firstName, setFirstName] = useState(childInfo.firstName || "");
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastName, setLastName] = useState(childInfo.lastName || "");
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [personalNumber, setPersonalNumber] = useState(
    childInfo.personalNumber || ""
  );
  const [personalNumberTouched, setPersonalNumberTouched] = useState(false);

  const handleBack = () => {
    navigate(VIEWS.CHOOSEISSUEOWNER);
  };

  const handleClick = () => {
    setChildInfo({
      firstName: properNameCase.convert(firstName),
      lastName: properNameCase.convert(lastName),
      personalNumber: personalNumber,
    });
    navigate(VIEWS.PATIENTQUESTION);
  };

  if (formIsSent) {
    return <Navigate to={VIEWS.CHOOSEISSUEOWNER} />;
  }

  let personalNumberError = "";
  if (!Personnummer.valid(personalNumber)) {
    personalNumberError = intl.formatMessage({
      id: "validationerror.pnr.format",
    });
  } else if (Personnummer.parse(personalNumber).getAge() > CHILD_MAX_AGE) {
    personalNumberError = intl.formatMessage({
      id: "validationerror.pnr.notchild",
    });
  }
  let disabled = !firstName || !lastName || personalNumberError;

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage id="registerchild.header" />
        </H1>
        <TextInputWithLabelAndError
          label={intl.formatMessage({ id: "firstname" })}
          id={"childFirstName"}
          value={firstName}
          required={true}
          autoFocus={true}
          onBlur={() => setFirstNameTouched(true)}
          handleChange={(e) => setFirstName(e)}
          validationError={
            firstName || !firstNameTouched
              ? ""
              : intl.formatMessage({ id: "validationerrror.firstname" })
          }
        />
        <br />
        <br />
        <TextInputWithLabelAndError
          label={intl.formatMessage({ id: "lastname" })}
          id={"childLastName"}
          value={lastName}
          required={true}
          onBlur={() => setLastNameTouched(true)}
          handleChange={(e) => setLastName(e)}
          validationError={
            lastName || !lastNameTouched
              ? ""
              : intl.formatMessage({ id: "validationerror.lastname" })
          }
        />
        <br />
        <br />
        <TextInputWithLabelAndError
          label={intl.formatMessage({ id: "pnr" })}
          id={"childPersonalNumber"}
          value={personalNumber}
          required={true}
          placeholder={intl.formatMessage({ id: "pnr.placeholder" })}
          onBlur={() => setPersonalNumberTouched(true)}
          handleChange={(e) => setPersonalNumber(e)}
          validationError={personalNumberTouched && personalNumberError}
        />
        <br />
        <br />
        <BackFrontButton
          disabled={disabled}
          handleBack={handleBack}
          handleForward={handleClick}
          backBtnText={intl.formatMessage({ id: "back" })}
          forwardBtnText={intl.formatMessage({ id: "next" })}
        />
      </Col>
    </Row>
  );
};
export default RegisterChildView;
