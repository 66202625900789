import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  H4,
  LoadingView,
  P,
  PrimaryButton,
  TextAreaWithLabelAndError,
  useGet,
  usePostForm,
} from "@collabodoc/component-library";
import { faEnvelope, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BackButton } from "../../components/BackFrontButtons";
import { CareCenterContext } from "../../components/CareCenterContext";
import { SENDER } from "../../constants/PatientPortalEnums";
import { API_URLS, VIEWS } from "../../URLS";
import Chat from "../answer/Chat";
import FileUpload from "../answer/FileUpload";

const Well = styled.div.attrs((props) => ({
  className: "well",
}))`
  overflow: auto;
`;

const AnswerView = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentCareCenterId, isLoadingCareCenters } =
    useContext(CareCenterContext);
  const { accessToken } = useOidcAccessToken();

  const {
    doGet: refetchIssue,
    response: issue,
    isLoading: loadingIssue,
  } = useGet(
    API_URLS.ISSUE(currentCareCenterId, location.state.issueId),
    accessToken
  );

  const { isLoading, doPostForm: sendMessage } = usePostForm(
    API_URLS.ISSUE_MESSAGES(currentCareCenterId, location.state.issueId),
    accessToken
  );
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");

  const handleBack = () => {
    navigate(VIEWS.PATIENT);
  };

  const handleNewIssueClick = () => {
    navigate(VIEWS.CHOOSEISSUEOWNER);
  };

  const handleSend = () => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("attachments", file);
    });
    data.append("messageText", message);

    sendMessage(data).then(() => {
      refetchIssue();
      setMessage("");
      setFiles([]);
    });
  };

  const handleMessageChange = (input) => {
    setMessage(input);
  };

  const closed = issue?.isClosed || issue?.isChatClosed;

  if (isLoadingCareCenters || !issue || isLoading || loadingIssue) {
    return <LoadingView />;
  }

  return (
    <Col md={{ span: 8, offset: 2 }}>
      <Chat issue={issue} currentSender={SENDER.PATIENT} />
      {closed ? (
        <Well>
          <H4>
            <FormattedMessage id="answer.header" />
          </H4>
          <P>
            <FormattedMessage id="answer.info" />
          </P>
        </Well>
      ) : (
        <Well>
          <TextAreaWithLabelAndError
            placeholder={intl.formatMessage({
              id: "answer.textarea.placeholder",
            })}
            value={message}
            autoFocus={true}
            minRows={3}
            maxRows={20}
            maxLength={500}
            whiteTextArea={true}
            handleChange={handleMessageChange}
          />
          {issue?.allowPatientFileUpload && (
            <FileUpload files={files} setFiles={setFiles} />
          )}
        </Well>
      )}
      <BackButton
        handleBack={handleBack}
        backBtnText={intl.formatMessage({ id: "back" })}
      />
      {closed ? (
        <PrimaryButton onClick={handleNewIssueClick} className="floatRight">
          <FormattedMessage id="newissue" /> <FontAwesomeIcon icon={faPlus} />
        </PrimaryButton>
      ) : (
        <PrimaryButton
          disabled={!message}
          onClick={handleSend}
          className="floatRight"
        >
          <FormattedMessage id="send" /> <FontAwesomeIcon icon={faEnvelope} />
        </PrimaryButton>
      )}
    </Col>
  );
};

export default AnswerView;
