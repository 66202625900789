import { BlobServiceClient } from "@azure/storage-blob"

const caregiver = window.REACT_APP_CAREGIVER;
const blobSasUrl = window.REACT_APP_STORAGE_ACCOUNT;
const blobServiceClient = new BlobServiceClient(blobSasUrl);

const getBlobAsString = async (container, blob) => {

    const containerName = container;
    const blobName = blob;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);

    const downloadBlockBlobResponse = await blobClient.download();
    var downloaded = await blobToString(await downloadBlockBlobResponse.blobBody)

    async function blobToString(blob) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onloadend = (ev) => {
                resolve(ev.target.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(blob);
        });
    }

    return downloaded;
};

const getActiveMessages = (result, careCenterId) => {

    var parsed = JSON.parse(result)
    var activeAlerts = [];

    parsed.forEach(alert => {
        if (!alert.caregivers || alert.caregivers.length === 0) {
            withinDateRange(alert) && activeAlerts.push(alert);
        }
        if (alert.caregivers && alert.caregivers.includes(caregiver)) {
            if (alert.carecenterids && alert.carecenterids.length > 0) {
                if (alert.carecenterids.includes(careCenterId)) {
                    withinDateRange(alert) && activeAlerts.push(alert);
                }
            }
            else {
                withinDateRange(alert) && activeAlerts.push(alert);
            }
        }
    });
    return activeAlerts;
}

const withinDateRange = (alert) => {
    var today = new Date();
    var fromdate = alert.fromdate && new Date(alert.fromdate);
    var todate = alert.todate && new Date(alert.todate);

    if (today >= fromdate && !todate) {
        return true;;
    }
    else if (!fromdate && today <= todate) {
        return true;
    }
    else if (!fromdate && !todate) {
        return true;
    }
    else if (today >= fromdate && today <= todate)
        return true;
    else {
        return false;
    }
}

export const getAlerts = async (careCenterId) => {
    return await getBlobAsString("patientportalalerts", "PatientPortalAlerts.txt")
        .then((result) => getActiveMessages(result, careCenterId))
};
