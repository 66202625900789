import { OidcProvider } from "@axa-fr/react-oidc";
import React from "react";
import { LoadingView } from "@collabodoc/component-library";
import { FormattedMessage, useIntl } from "react-intl";

export const AuthProvider = ({ children }) => {
  const baseUrl = window.location.protocol + "//" + window.location.host;

  const configuration = {
    client_id: window.REACT_APP_OIDC_CLIENT_ID,
    redirect_uri: `${baseUrl}/callback`,
    silent_redirect_uri: `${baseUrl}/authentication/silent_callback`,
    scope: `${window.REACT_APP_OIDC_SCOPE}`,
    authority: `${window.REACT_APP_IDENTITYSERVER_URL}`,
    service_worker_only: false,
  };

  return (
    <OidcProvider
      configuration={configuration}
      authenticatingComponent={Authenticating}
      callbackSuccessComponent={Connecting}
      callbackErrorComponent={AuthError}
      sessionLostComponent={LostSession}
      authenticatingErrorComponent={AuthError}
      loadingComponent={LoadingView}
    >
      {children}
    </OidcProvider>
  );
};

export const NotAuthenticated = () => {
  return <LoadingView />;
};

export const NotAuthorized = () => {
  const intl = useIntl();
  return (
    <LoadingView message={intl.formatMessage({ id: "auth.notauthorized" })} />
  );
};

export const Authenticating = () => {
  return <LoadingView />;
};

export const LostSession = () => {
  const handleClick = () => (window.location.href = "/");
  return (
    <div>
      <h1 className={"textCenter marginTop50"}>
        <FormattedMessage
          id="auth.loggedout"
          description="You can't see this page"
        />
      </h1>
      <button onClick={handleClick} className="btn btn-primary ">
        <FormattedMessage id="auth.tostartpage" />
      </button>
    </div>
  );
};

export const AuthError = () => {
  const handleClick = () => (window.location.href = "/");
  return (
    <div>
      <h1 className={"textCenter marginTop50"}>
        <FormattedMessage
          id="auth.error"
          description="You can't see this page"
        />
      </h1>
      <button onClick={handleClick} className="btn btn-primary ">
        <FormattedMessage id="auth.tostartpage" />
      </button>
    </div>
  );
};

export const Connecting = () => {
  return <LoadingView />;
};
