import { P } from "@collabodoc/component-library";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { getAlerts } from "../Blob";
import { CareCenterContext } from "../components/CareCenterContext";

const AlertMessage = ({ alert }) => {

    const [show, setShow] = useState(true);

    return (
        show &&
        <Row>
            <Col md={{ span: 8, offset: 2 }}>
                <Alert variant={alert.bootstrapalerttype} onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>{alert.heading}</Alert.Heading>
                    <AlertParagraph>{alert.paragraph}</AlertParagraph>
                </Alert>
            </Col>
        </Row>
    );
};


const AlertMessages = () => {

    const { currentCareCenterId } = useContext(CareCenterContext);
    const [alerts, setAlerts] = useState(null);

    useEffect(() => {
        getAlerts(currentCareCenterId).then((result) => setAlerts(result));
    }, [currentCareCenterId]);

    return (
        alerts &&
        <Alerts>
            <Col>
                {alerts.map((alert, key) => {
                    return (
                        <AlertMessage alert={alert} key={key} />
                    );
                })}
            </Col>
        </Alerts>
    );
};

const Alerts = styled(Row)`
    margin-top: 10px;
  `;

const AlertParagraph = styled(P)`
    margin-bottom: 5px;
`;

export default AlertMessages;