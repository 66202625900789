import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import properNameCase from "proper-name-case";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BackButton } from "../../components/BackFrontButtons";
import {
  B,
  emptyResultResolver,
  H1,
  P,
  PrimaryButton,
  usePost,
} from "@collabodoc/component-library";
import { SESSION_STORAGE } from "../../constants/storage";
import { RegistrationContext } from "../../RegistrationContext";
import { API_URLS, RCVIEWS } from "../../URLS";
import createMessageText from "../../components/createMessageText";
import { FormattedMessage, useIntl } from "react-intl";
import { CareCenterContext } from "../../components/CareCenterContext";
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";
import { APPURLPREFIXES } from "../../constants/PatientPortalEnums";

const Header = styled(B)`
  margin-bottom: 0px;
`;

const ConfirmMessageView = () => {
  const intl = useIntl();

  const { getContactReasonName } = useContext(RegistrationContext);
  const {
    subjectState,
    childInfo,
    contactReasonId,
    message,
    patientContactInfo,
  } = useContext(RegistrationContext);
  const { accessToken } = useOidcAccessToken();
  const { oidcUser } = useOidcUser();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const chevron = collapsed ? "fa fa-chevron-down" : "fa fa-chevron-up";
  const disabled = !message.patientText || !patientContactInfo.phoneNumber;
  const childFirstName = childInfo.firstName ? childInfo.firstName : "";
  const childLastName = childInfo.lastName ? childInfo.lastName : "";

  const { currentCareCenterId } = useContext(CareCenterContext);
  const {
    doPost: sendIssue,
    isLoading,
    error,
    success,
  } = usePost(
    API_URLS.ISSUES(currentCareCenterId),
    accessToken,
    emptyResultResolver
  );
  const [messageText, setMessageText] = useState(null);
  useEffect(() => {
    message &&
      setMessageText(
        createMessageText(message, intl, APPURLPREFIXES.RELATIVECONTACT)
      );
  }, [message, intl]);

  const handleSave = async () => {
    sendIssue({
      PhoneNumber: patientContactInfo.phoneNumber,
      MessageText: messageText,
      Email: patientContactInfo.email,
      FirstName: properNameCase.convert(oidcUser.given_name),
      LastName: properNameCase.convert(oidcUser.family_name),
      TimeOfRegistrationStart: sessionStorage.getItem(
        SESSION_STORAGE.TIME_OF_REGISTRATION_START
      ),
      ChildPersonalNumber: childInfo.personalNumber,
      ChildFirstName: childInfo.firstName,
      ChildLastName: childInfo.lastName,
      ContactReasonId: contactReasonId,
    });
  };

  const handleBack = () => {
    navigate(RCVIEWS.PATIENTCONTACTINFO);
  };

  const handleReturn = () => {
    sessionStorage.removeItem(SESSION_STORAGE.TIME_OF_REGISTRATION_START);
    navigate(RCVIEWS.PATIENT);
  };

  const toggleQuestion = () => {
    setCollapsed(!collapsed);
  };

  if (!isLoading && success) {
    return <Navigate to={RCVIEWS.CONFIRMATION} />;
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage
            id="confirmmessage.header"
            description="Confirm content"
          />
        </H1>
        <div className="well">
          <Header>
            <FormattedMessage id="confirmmessage.yourphonenumber" />
          </Header>
          <P>{patientContactInfo.phoneNumber}</P>
          {patientContactInfo.email && (
            <Fragment>
              <Header>
                <FormattedMessage id="confirmmessage.youremail" />
              </Header>
              <P>{patientContactInfo.email}</P>
            </Fragment>
          )}
          {!subjectState.isSelf ? (
            <div>
              <Header>
                <FormattedMessage id="confirmmessage.relativename" />
              </Header>
              <P>{`${childFirstName} ${childLastName}`}</P>
              <Header>
                <FormattedMessage id="confirmmessage.relativepnr" />
              </Header>
              <P>{childInfo.personalNumber}</P>
            </div>
          ) : (
            ""
          )}
          <Header>
            <FormattedMessage id="confirmmessage.contactreason" />
          </Header>
          <P>{getContactReasonName(contactReasonId)}</P>
          <Header>
            <FormattedMessage id="confirmmessage.yourmessage" />
          </Header>
          {messageText && messageText.length > 100 ? (
            collapsed ? (
              <div className="preWrapText">
                {messageText.length > 100
                  ? messageText.substring(0, 100) + "..."
                  : messageText}
                <br />
                <PrimaryButton className="linkBtn" onClick={toggleQuestion}>
                  <span className={`${chevron}`} />
                  <FormattedMessage
                    id="confirmmessage.showmessage"
                    description="Show whole message"
                  />
                </PrimaryButton>
              </div>
            ) : (
              <div className="preWrapText">
                {messageText}
                <br />
                <PrimaryButton className="linkBtn" onClick={toggleQuestion}>
                  <span className={`${chevron}`} />
                  <FormattedMessage
                    id="confirmmessage.hidemessage"
                    description="Show less"
                  />
                </PrimaryButton>
              </div>
            )
          ) : (
            messageText
          )}
        </div>
        <div className="textCenter">
          <BackButton
            handleBack={handleBack}
            backBtnText={intl.formatMessage({ id: "back" })}
          />
          <PrimaryButton
            disabled={disabled}
            className="bigBtn btn-success leftMargin-md"
            onClick={handleSave}
          >
            <FormattedMessage id="confirm" />
          </PrimaryButton>
        </div>
        <br />
        <div className="textCenter">
          {error && (
            <B className="dangerText">
              <FormattedMessage
                id="confirmmessage.errortext"
                description="Error occured when sending message..."
                values={{
                  br: <br />,
                }}
              />
              <PrimaryButton className="linkBtnDanger" onClick={handleReturn}>
                <FormattedMessage id="here" />
              </PrimaryButton>
            </B>
          )}
        </div>
      </Col>
    </Row>
  );
};
export default ConfirmMessageView;
