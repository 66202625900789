import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { usePut } from "@collabodoc/component-library";
import { SENDER } from "../../constants/PatientPortalEnums";
import { API_URLS, RCVIEWS } from "../../URLS";
import { getCalendarDateFormat } from "../../utils/dateUtils";
import { FormattedMessage } from "react-intl";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { CareCenterContext } from "../../components/CareCenterContext";

const IssueListItem = ({ issue }) => {
  const latestMessage = issue.messages[0];
  const isAnswer = latestMessage.senderEnum === SENDER.CAREGIVER;
  const isReadAnswer = isAnswer && latestMessage.timeOfRead !== null;
  const { accessToken } = useOidcAccessToken();
  const navigate = useNavigate();
  const { currentCareCenterId } = useContext(CareCenterContext);
  const { doPut: setToRead } = usePut(
    API_URLS.ISSUE_MESSAGE_READ(
      currentCareCenterId,
      issue.issueId,
      latestMessage.messageId
    ),
    accessToken
  );

  const handleClick = async (e) => {
    e.preventDefault();
    if (!isReadAnswer) {
      await setToRead();
    }
    navigate(RCVIEWS.ANSWER, { state: { issueId: issue.issueId } });
  };

  const faIcon = isReadAnswer
    ? "fa fa-envelope-open"
    : isAnswer
    ? "fa fa-envelope"
    : "";
  const fontWeight = isAnswer ? (!isReadAnswer ? "boldText" : "") : "";

  return (
    <div onClick={handleClick} className="well issueItem">
      <div className="floatRight">
        {getCalendarDateFormat(latestMessage.timeOfCreated)}
      </div>
      <div className={fontWeight}>
        {issue.contactReason.name} <span className={faIcon} />
        <br />
        {!issue.selfRegistration && (
          <span>
            <FormattedMessage id="issuelistitem.regarding" />{" "}
            {issue.issueOwner.firstName} {issue.issueOwner.lastName}
            <br />
          </span>
        )}
        <i>
          {latestMessage.messageText &&
            latestMessage.messageText.substring(0, 25) + "..."}
        </i>
        <br />
        <br />
      </div>
    </div>
  );
};
export default IssueListItem;
