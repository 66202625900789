const SENDER = {
    PATIENT: 0,
    CAREGIVER: 1
};
const FQUESTIONS = {
    THOUGHT: 'F1_thought',
    CONCERN: 'F2_concern',
    WISH: 'F3_wish'
}

const APPURLPREFIXES = {
    DIGITALCONTACT: 'digitalcontact',
    RELATIVECONTACT: 'relativecontact'
}

export {FQUESTIONS, SENDER, APPURLPREFIXES};