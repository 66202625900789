import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useGet } from "@collabodoc/component-library";
import Cookies from "js-cookie";
import React, { createContext, useState } from "react";
import { COOKIE } from "../constants/storage";
import { API_URLS } from "../URLS";

const CareCenterContext = createContext({});
const CareCenterContextProvider = ({ children }) => {
  const { accessToken } = useOidcAccessToken();
  const [currentCareCenterId, setCurrentCareCenterId] = useState(
    parseInt(Cookies.get(COOKIE.CARE_CENTER_ID)) || null
  );

  const {
    response: careCenters,
    isLoading: isLoadingCareCenters,
    doGet: reFetchCareCenters,
  } = useGet(API_URLS.GET_CARECENTERS, accessToken);

  const currentCareCenter =
    currentCareCenterId &&
    careCenters &&
    careCenters.find((careCenter) => careCenter.id === currentCareCenterId);

  const setCurrentCareCenter = (careCenterId) => {
    Cookies.set(COOKIE.CARE_CENTER_ID, careCenterId);
    setCurrentCareCenterId(parseInt(careCenterId));
  };
  const removeCurrentCareCenter = () => {
    Cookies.remove(COOKIE.CARE_CENTER_ID);
    setCurrentCareCenterId(null);
  };
  return (
    <CareCenterContext.Provider
      value={{
        careCenters,
        currentCareCenter,
        currentCareCenterId,
        isLoadingCareCenters,
        setCurrentCareCenter,
        removeCurrentCareCenter,
        reFetchCareCenters,
      }}
    >
      {children}
    </CareCenterContext.Provider>
  );
};
export { CareCenterContext, CareCenterContextProvider };
