import { Navigate } from "react-router-dom";
import { VIEWS } from "./URLS";
import currentApp from "./utils/checkApp";

const Callback = () => {
  const urlPrefix = currentApp(window.location.pathname);

  return <Navigate to={urlPrefix + VIEWS.PATIENT} />;
};

export default Callback;
