import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { BackButton } from "../../components/BackFrontButtons";
import { SESSION_STORAGE } from "../../constants/storage";
import { RegistrationContext } from "../../RegistrationContext";
import { FormattedMessage, useIntl } from "react-intl";
import { H2 } from "@collabodoc/component-library";
import { VIEWS } from "../../URLS";

const ChooseIssueOwnerView = () => {
  const intl = useIntl();
  const { setSubject, setFormIsSent } = useContext(RegistrationContext);
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem(
      SESSION_STORAGE.TIME_OF_REGISTRATION_START,
      new Date().toJSON()
    );

    setFormIsSent(false);
  }, []);

  const handleClick = (e, selfRegistration) => {
    e.preventDefault();
    if (selfRegistration) {
      setSubject({ isSelf: true });
      navigate(VIEWS.PATIENTQUESTION);
    } else {
      setSubject({ isSelf: false });
      navigate(VIEWS.REGISTERCHILD);
    }
  };

  const back = () => {
    navigate(VIEWS.PATIENT);
  };

  return (
    <Col md={{ span: 8, offset: 2 }}>
      <H2>
        <FormattedMessage
          id="chooseissueowner.header"
          description="Who is the issue regarding?"
        />
      </H2>
      <Row>
        <Col
          xs={6}
          lg={4}
          id="symptomCardID"
          onClick={(e) => {
            handleClick(e, true);
          }}
        >
          <div className="symptom-card symptom-card-primary">
            <svg
              className="arrow-card collabodoc-arrow-dark"
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMaxYMid meet"
              viewBox="0 0 51.61 84.46"
            >
              <title>arrow-right</title>
              <path
                className="cls-1"
                d="M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z"
                transform="translate(-7058.74 -1534.14)"
              />
            </svg>
            <span className="symptom-card-title">
              <FormattedMessage id="chooseissueowner.button.me" />
            </span>
          </div>
        </Col>
        <Col
          xs={6}
          lg={4}
          id="symptomCardID"
          onClick={(e) => {
            handleClick(e, false);
          }}
        >
          <div className="symptom-card symptom-card-primary">
            <svg
              className="arrow-card collabodoc-arrow-dark"
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMaxYMid meet"
              viewBox="0 0 51.61 84.46"
            >
              <title>arrow-right</title>
              <path
                className="cls-1"
                d="M7087.7,1576.37l-26.21,26.21a9.38,9.38,0,0,0,13.27,13.27L7107.6,1583a9.37,9.37,0,0,0,0-13.27l-32.84-32.85a9.38,9.38,0,0,0-13.27,13.27Z"
                transform="translate(-7058.74 -1534.14)"
              />
            </svg>
            <span className="symptom-card-title">
              <FormattedMessage id="chooseissueowner.button.child" />
            </span>
          </div>
        </Col>
      </Row>
      <BackButton
        handleBack={back}
        backBtnText={intl.formatMessage({ id: "back" })}
      />
    </Col>
  );
};

export default ChooseIssueOwnerView;
