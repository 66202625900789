import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import logo from "../../Content/Images/Pictograms/CollaboDoc-Pictogram-Talk-2.svg";

import { FormattedMessage, useIntl } from "react-intl";
import { CareCenterContext } from "../../components/CareCenterContext";
import {
  H2,
  H3,
  LoadingView,
  PrimaryButton,
} from "@collabodoc/component-library";
import { RCVIEWS } from "../../URLS";

const InformationView = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { isLoadingCareCenters, currentCareCenter } =
    useContext(CareCenterContext);

  const handleClick = (e) => {
    e.preventDefault();
    navigate(RCVIEWS.TERMSANDCONDITIONS);
  };

  if (isLoadingCareCenters) {
    return <LoadingView />;
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <Row>
          <Col xs={10}>
            <H2>
              <FormattedMessage
                id="relativecontact.information.header"
                description="Welcome to relative contact text header"
                values={{
                  carecenter: currentCareCenter?.name,
                }}
              />
            </H2>
          </Col>
          <Col xs={2}>
            <img
              src={logo}
              className="information-icon"
              alt={intl.formatMessage({ id: "information.icon.alt" })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ul className={"list"}>
              <li className={"listItem"}>
                <FormattedMessage
                  id="relativecontact.information.listitem.who"
                  description="Choose who the issue applies to"
                />
              </li>
              <li className={"listItem"}>
                <FormattedMessage
                  id="relativecontact.information.listitem.briefly"
                  description="Choose who the issue applies to"
                />
              </li>
              <li className={"listItem"}>
                <FormattedMessage
                  id="relativecontact.information.listitem.contactinformation"
                  description="Give contact information"
                />
              </li>
              <li className={"listItem"}>
                <FormattedMessage
                  id="relativecontact.information.listitem.response"
                  description="Get message when you get a response"
                />
              </li>
            </ul>
            <div className="well">
              <H3>
                <FormattedMessage
                  id="information.bankidrequirement"
                  description="BankId is required"
                />
              </H3>
            </div>
            <PrimaryButton className="btn-block textLeft" onClick={handleClick}>
              <FormattedMessage id="next" />{" "}
              <span className={"fa fa-chevron-right floatRight topMargin-xs"} />
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default InformationView;
