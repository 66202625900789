import { collabodocTheme } from "@collabodoc/component-library";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AuthProvider } from "./Auth";
import { routes } from "./routes";
import { getTranslations, LOCALE } from "./utils/localeUtils";

const router = createBrowserRouter(routes);

const App = () => {
  useEffect(
    () =>
      document.getElementsByTagName("html")[0].classList.add("theme-default"),
    []
  );

  return (
    <IntlProvider locale={LOCALE} messages={getTranslations()}>
      <div
        className="body-content theme-default patientcontact"
        id="maincontainer"
      >
        <ThemeProvider theme={collabodocTheme}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </ThemeProvider>
      </div>
    </IntlProvider>
  );
};

export default App;
