import {TextButton, ValidationError} from "@collabodoc/component-library";
import {faPaperclip, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from "react";
import styled from "styled-components";

const MAX_FILE_COUNT = 5;
const MAX_FILE_SIZE_IN_MB = 3;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const ACCEPTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];

const getFileSubType = fileType => fileType.split("/").pop();
const getAcceptedFileTypesString = () => ACCEPTED_FILE_TYPES.map(fileType => getFileSubType(fileType)).join(" eller ")

const FileList = styled.ul`
  list-style: none;
  padding-left: 0px;

  li {
    list-style: none;
  }
`

const FileUpload = ({files, setFiles}) => {
    const [fileValidationErrors, setFileValidationErrors] = useState([]);
    const fileInputRef = useRef();

    const handleFileChange = ({target}) => {
        const fileValidationErrors = [];
        const filesArray = Array.from(target.files);

        if (filesArray.length + files.length > MAX_FILE_COUNT) {
            fileValidationErrors.push(`Du kan max välja ${MAX_FILE_COUNT} filer.`)
        } else {
            filesArray.forEach(file => {
                if(!ACCEPTED_FILE_TYPES.includes(file.type)) {
                    fileValidationErrors.push(`Filen måste vara av typen ${getAcceptedFileTypesString()}.`);
                }
                else if (file.size > MAX_FILE_SIZE_IN_BYTES) {
                    fileValidationErrors.push(`${file.name} är för stor för att laddas upp.`);
                }
                else if(files.some(f => f.name === file.name)) {
                    fileValidationErrors.push(`Du har redan valt en fil med namnet ${file.name}.`);
                }
                else {
                    files.push(file)
                }
            });
        }

        setFileValidationErrors(fileValidationErrors);
        setFiles(files);
    }

    const HandleDeleteFile = (index) => {
        files.splice(index, 1);
        setFiles([...files]);
    }

    return (
        <>
            <div>
                {fileValidationErrors.map((error, index) => (
                    <div key={index}>
                        <ValidationError>{error}</ValidationError>
                    </div>
                ))}
            </div>
            <TextButton onClick={() => fileInputRef.current.click()}><FontAwesomeIcon icon={faPaperclip}
                                                                                      className={"rightMargin-sm"}/>Välj
                filer att bifoga</TextButton>
            <input hidden
                   ref={fileInputRef}
                   onChange={handleFileChange}
                   id="fileUpload"
                   type="file"
                   accept={ACCEPTED_FILE_TYPES.join(", ")}
                   multiple/>

            {files.length > 0 &&
            <FileList>
                {files.map((file, index) => (
                    <li key={index}>
                        <TextButton onClick={() => HandleDeleteFile(index)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </TextButton>{file.name}
                    </li>
                ))}
            </FileList>
            }
        </>
    )
}

export default FileUpload;
