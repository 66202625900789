import {
  DropDown,
  H1,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { BackFrontButton } from "../../components/BackFrontButtons";
import { FormattedMessage, useIntl } from "react-intl";
import { RegistrationContext } from "../../RegistrationContext";
import { VIEWS } from "../../URLS";

const PatientQuestionView = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    contactReasonId,
    setContactReasonId,
    message,
    setMessage,
    subjectState,
    contactReasons,
    getContactReasonName,
    formIsSent,
    childInfo,
  } = useContext(RegistrationContext);

  const handleClick = (e) => {
    e.preventDefault();
    navigate(VIEWS.FQUESTIONS);
  };

  const handleInput = (input) => {
    setMessage((prevState) => ({
      ...prevState,
      patientText: input,
    }));
  };

  const handleBack = () => {
    if (subjectState.isSelf) {
      navigate(VIEWS.CHOOSEISSUEOWNER);
    } else {
      navigate(VIEWS.REGISTERCHILD);
    }
  };

  const options = contactReasons?.map(({ id, name }) => {
    return {
      id: id,
      text: name,
    };
  });

  const allPreviousChildInfo =
    childInfo.firstName && childInfo.lastName && childInfo.personalNumber;

  if (
    (subjectState.isSelf && formIsSent) ||
    (!subjectState.isSelf && (formIsSent || !allPreviousChildInfo))
  ) {
    return <Navigate to={VIEWS.CHOOSEISSUEOWNER} />;
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage id="patientquestion.header" />
        </H1>
        <Row>
          <Col>
            <label className="question boldText" htmlFor={"contactReason"}>
              <FormattedMessage id="patientquestion.contactreason.choose" />
              <span className="asterisk"> *</span>
            </label>
            <DropDown
              placeholder={intl.formatMessage({
                id: "patientquestion.contactreason.choose",
              })}
              options={options}
              selected={
                contactReasonId && getContactReasonName(contactReasonId)
              }
              handler={(cr) => setContactReasonId(cr)}
              sort={true}
            ></DropDown>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextAreaWithLabelAndError
              id={"PatientMessage"}
              label={intl.formatMessage({
                id: "patientquestion.patientmessage.title",
              })}
              placeholder={intl.formatMessage({
                id: "patientquestion.patientmessage.placeholder",
              })}
              required={true}
              value={message.patientText}
              minRows={4}
              maxLength={500}
              handleChange={handleInput}
            />
          </Col>
        </Row>
        <br />
        <BackFrontButton
          disabled={!message.patientText || !contactReasonId}
          handleBack={handleBack}
          handleForward={handleClick}
          backBtnText={intl.formatMessage({ id: "back" })}
          forwardBtnText={intl.formatMessage({ id: "next" })}
        />
      </Col>
    </Row>
  );
};
export default PatientQuestionView;
