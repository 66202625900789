import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import React, { useContext } from "react";
import { SESSION_STORAGE } from "../constants/storage";
import logo from "../Content/Images/Logo/collabodoc_light.png";
import { CareCenterContext } from "./CareCenterContext";
import { FormattedMessage } from "react-intl";
import { NavBar } from "@collabodoc/component-library";
import { useLocation } from "react-router-dom";
import { VIEWS } from "../URLS";

const NavMenu = () => {
  const { oidcUser } = useOidcUser();
  const { logout } = useOidc();
  const location = useLocation();
  const path = location.pathname.toLowerCase();
  const logouttext = [VIEWS.PATIENT, VIEWS.ANSWER].includes(path) ? (
    <FormattedMessage id="logout" />
  ) : (
    <FormattedMessage id="abort" />
  );
  const { currentCareCenterId } = useContext(CareCenterContext);

  const handleCancel = () => {
    if (!currentCareCenterId) {
      return;
    }
    if (oidcUser) {
      sessionStorage.removeItem(SESSION_STORAGE.TIME_OF_REGISTRATION_START);
      logout();
    }
  };

  const items = [
    {
      label: logouttext,
      handler: handleCancel,
    },
  ];

  return <NavBar logo={logo} items={items} className="navbarLogo" />;
};

export default NavMenu;
