import Personnummer from "personnummer";
import properNameCase from "proper-name-case";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BackFrontButton } from "../../components/BackFrontButtons";
import { RegistrationContext } from "../../RegistrationContext";

import { FormattedMessage, useIntl } from "react-intl";
import { H1, TextInputWithLabelAndError } from "@collabodoc/component-library";
import { RCVIEWS } from "../../URLS";
import { useNavigate } from "react-router-dom";

const RegisterChildView = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { childInfo, setChildInfo } = useContext(RegistrationContext);
  const [firstName, setFirstName] = useState(childInfo.firstName);
  const [lastName, setLastName] = useState(childInfo.lastName);
  const [personalNumber, setPersonalNumber] = useState(
    childInfo.personalNumber
  );
  const [disabled, setDisabled] = useState(false);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [personalNumberError, setPersonalNumberError] = useState(null);

  useEffect(() => {
    let disabled = !firstName || !lastName || !personalNumber;
    setDisabled(disabled);
  }, [firstName, lastName, personalNumber]);

  const handleFirstNameInput = (input) => {
    if (input) {
      setFirstName(properNameCase.convert(input));
      setFirstNameError(null);
    } else {
      setFirstName(null);
      setFirstNameError(
        intl.formatMessage({ id: "validationerrror.firstname" })
      );
    }
  };

  const handleLastNameInput = (input) => {
    if (input) {
      setLastName(properNameCase.convert(input));
      setLastNameError(null);
    } else {
      setLastName(null);
      setLastNameError(intl.formatMessage({ id: "validationerror.lastname" }));
    }
  };

  const handlePersonalNumberInput = (input) => {
    setPersonalNumber(input);
    if (!input || input.length < 12 || !Personnummer.valid(input)) {
      setPersonalNumberError(
        intl.formatMessage({ id: "validationerror.pnr.format" })
      );
    } else {
      setPersonalNumberError(null);
    }
  };

  const handleBack = () => {
    navigate(RCVIEWS.CHOOSEISSUEOWNER);
  };

  const handleClick = () => {
    setChildInfo({
      firstName: firstName,
      lastName: lastName,
      personalNumber: personalNumber,
    });
    navigate(RCVIEWS.PATIENTQUESTION);
  };

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage id="registerrelative.header" />
        </H1>
        <TextInputWithLabelAndError
          label={intl.formatMessage({ id: "firstname" })}
          id={"childFirstName"}
          value={firstName}
          required={true}
          autoFocus={true}
          handleChange={handleFirstNameInput}
          validationError={firstNameError}
        />
        <br />
        <br />
        <TextInputWithLabelAndError
          label={intl.formatMessage({ id: "lastname" })}
          id={"childLastName"}
          value={lastName}
          required={true}
          handleChange={handleLastNameInput}
          validationError={lastNameError}
        />
        <br />
        <br />
        <TextInputWithLabelAndError
          label={intl.formatMessage({ id: "pnr" })}
          id={"childPersonalNumber"}
          value={personalNumber}
          required={true}
          placeholder={intl.formatMessage({ id: "pnr.placeholder" })}
          handleChange={handlePersonalNumberInput}
          validationError={personalNumberError}
        />
        <br />
        <br />
        <BackFrontButton
          disabled={disabled}
          handleBack={handleBack}
          handleForward={handleClick}
          backBtnText={intl.formatMessage({ id: "back" })}
          forwardBtnText={intl.formatMessage({ id: "next" })}
        />
      </Col>
    </Row>
  );
};
export default RegisterChildView;
