import Linkify from "linkifyjs/react";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {getCalendarDateFormat} from "../../utils/dateUtils";
import {AttachmentsList} from "./AttachmentsList";

const ChatMessage = ({message, currentSender, currentSenderName, currentReceiverName}) => {

    const datetime = getCalendarDateFormat(message.timeOfCreated);

    return (
        message.senderEnum === currentSender ?
            <Row>
                <Col xs={{span: 10, offset: 2}} md={{span: 8, offset: 4}} className="floatRight">
                    {currentSenderName}
                    <div className="currentSender">
                        <AttachmentsList attachments={message.attachments}/>
                        <Linkify options={{target: '_blank'}}>{message.messageText}</Linkify>
                    </div>
                    <div className="floatRight paddingRight10 smallText">
                        <i>{datetime}</i>
                    </div>
                </Col>
            </Row>
            :
            <Row>
                <Col xs={10} md={8} className="floatLeft">
                    <div className="leftMargin-md">{currentReceiverName}</div>
                    <div className="currentReceiver">
                        <AttachmentsList attachments={message.attachments}/>
                        <Linkify options={{target: '_blank'}}>{message.messageText}</Linkify>
                    </div>
                    <div className="floatRight smallText">
                        <i>{datetime}</i>
                    </div>
                </Col>
            </Row>

    );
}

export default ChatMessage;
