import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { TextButton, useGetBlob } from "@collabodoc/component-library";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { CareCenterContext } from "../../components/CareCenterContext";
import { API_URLS } from "../../URLS";

export const AttachmentsList = ({ attachments }) => {
  const { accessToken } = useOidcAccessToken();
  const { currentCareCenterId } = useContext(CareCenterContext);
  const { doGetBlob } = useGetBlob(null, accessToken);

  const downloadFile = (fileId, fileName) =>
    doGetBlob(API_URLS.DOWNLOAD(currentCareCenterId, fileId)).then((blob) => {
      const file = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = file;
      link.download = fileName;
      link.click();
    });

  return (
    attachments.length > 0 && (
      <div className={"attachment-section"}>
        {attachments?.map(({ fileId, fileName }) => (
          <div key={fileId}>
            <FontAwesomeIcon icon={faPaperclip} />
            <TextButton
              className={"attachment-text"}
              onClick={() => downloadFile(fileId, fileName)}
            >
              {fileName}
            </TextButton>
          </div>
        ))}
      </div>
    )
  );
};
