import React from "react";
import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import AlertMessages from "./components/AlertMessages";
import { CareCenterContextProvider } from "./components/CareCenterContext";
import NavMenu from "./components/NavMenu";
import { setMomentLocale } from "./utils/dateUtils";

const Root = () => {
  const intl = useIntl();
  setMomentLocale(intl);
  return (
    <CareCenterContextProvider>
      <NavMenu />
      <AlertMessages />
      <Container fluid>
        <Outlet />
      </Container>
    </CareCenterContextProvider>
  );
};

export default Root;
