import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { CareCenterContext } from "../../components/CareCenterContext";
import ChooseCareCenterView from "../digitalcontact/ChooseCareCenterView";

const CareCenterRequirement = () => {
  const { currentCareCenterId } = useContext(CareCenterContext);

  if (!currentCareCenterId) {
    return <ChooseCareCenterView />;
  } else {
    return <Outlet />;
  }
};

export default CareCenterRequirement;
