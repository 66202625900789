import { DropDown, H4, LoadingView } from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import { CareCenterContext } from "../../components/CareCenterContext";
import { VIEWS } from "../../URLS";

const ChooseCareCenterView = () => {
  const navigate = useNavigate();
  const { isLoadingCareCenters, careCenters, setCurrentCareCenter } =
    useContext(CareCenterContext);

  const setCareCenter = (id) => {
    setCurrentCareCenter(id);
    navigate(VIEWS.INFORMATION);
  };

  if (isLoadingCareCenters) {
    return <LoadingView />;
  }

  if (careCenters && careCenters.length === 1) {
    setCurrentCareCenter(careCenters[0].id);
    return <Navigate to={VIEWS.INFORMATION} />;
  }

  return (
    <>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="chooseCareCenter">
          <H4>
            <FormattedMessage id="choosecarecenter.header" />
          </H4>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <DropDown
            placeholder={<FormattedMessage id="choosecarecenter.header" />}
            options={careCenters?.map((x) => ({ text: x.name, id: x.id }))}
            handler={(id) => setCareCenter(id)}
          ></DropDown>
        </Col>
      </Row>
    </>
  );
};
export default ChooseCareCenterView;
