import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { SESSION_STORAGE } from "../../constants/storage";
import { RegistrationContext } from "../../RegistrationContext";

import { FormattedMessage } from "react-intl";
import { H1, P, PrimaryButton } from "@collabodoc/component-library";
import { RCVIEWS } from "../../URLS";

const IssueCreatedView = () => {
  const navigate = useNavigate();
  const { patientContactInfo, setMessage } = useContext(RegistrationContext);
  const handleClick = () => {
    sessionStorage.removeItem(SESSION_STORAGE.TIME_OF_REGISTRATION_START);
    setMessage({
      patientText: null,
      thoughtText: null,
      concernText: null,
      wishText: null,
    });
    navigate(RCVIEWS.PATIENT);
  };

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <H1>
          <FormattedMessage id="issuecreated.header" description="Thank you!" />
        </H1>
        <P>
          <FormattedMessage
            id="issuecreated.info.message"
            description="Your receive a message on your phone when issue is answered"
            values={{
              andemail: patientContactInfo.email ? (
                <FormattedMessage id="issuecreated.info.message.andemail" />
              ) : (
                ""
              ),
            }}
          />
        </P>
        <P>
          <FormattedMessage
            id="issuecreated.info.link"
            description="You read the answer by clicking link in message"
          />
        </P>
        <PrimaryButton className="textLeft" onClick={handleClick}>
          <FormattedMessage id="issuecreated.back" />
        </PrimaryButton>
      </Col>
    </Row>
  );
};
export default IssueCreatedView;
