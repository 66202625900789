import { H1, P, PrimaryButton } from "@collabodoc/component-library";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { RCVIEWS } from "../../URLS";
import { useNavigate } from "react-router-dom";

const TermsAndConditionsView = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RCVIEWS.PATIENT);
  };

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }}>
        <Row>
          <Col>
            <H1>
              <FormattedMessage id="termsandcondition.header" />
            </H1>
            <P>
              <FormattedMessage
                id="termsandcondition.conditions"
                description="Conditions for handling of data"
                values={{
                  br: <br />,
                }}
              />
            </P>
            <br />
            <PrimaryButton
              className="btn-block textLeft"
              id="continueBtn"
              onClick={handleClick}
            >
              <FormattedMessage id="next" />
              <span className={"fa fa-chevron-right floatRight topMargin-xs"} />
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TermsAndConditionsView;
