import React from "react";
import ChatInner from "./ChatInner";


const Chat = ({ issue, currentSender}) => {

    return (
        <div className={"well chatwell"}>
            <ChatInner issue={issue} currentSender={currentSender}/>
        </div>
    );
};

export default Chat;
        
        