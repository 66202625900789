import { LoadingView } from "@collabodoc/component-library";
import React, { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { CareCenterContext } from "./CareCenterContext";

const CareCenterRedirect = ({ url }) => {
  const { carecenterId } = useParams();
  const { careCenters = [], setCurrentCareCenter } =
    useContext(CareCenterContext);

  const careCenterExists = careCenters
    .map((cc) => cc.id)
    .includes(Number(carecenterId));

  useEffect(() => {
    if (careCenterExists) {
      setCurrentCareCenter(carecenterId);
    }
  }, [setCurrentCareCenter, carecenterId, careCenterExists]);

  if (!careCenters.length) {
    return <LoadingView />;
  }
  return <Navigate to={url} />;
};

export default CareCenterRedirect;
